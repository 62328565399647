import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as b2b2cActions from "../../../redux/actions/b2b2c-actions";
import Card from "../../../images/cap-partner-card.png";
import ReactGA from 'react-ga';
import postscribe from "postscribe";
import { Row, Col } from "antd";
import config from "../../../../../config";

ReactGA.initialize('G-N02B8BDNND');
ReactGA.plugin.require('ecommerce');
class ThankYou extends Component {
  async componentDidMount() {
    postscribe("#thank-you","<script> (function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2750169,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); </script>");   
    var order_response = JSON.parse(localStorage.getItem("order_response"));
    console.log("order_response -------------- ");
    console.log(order_response);

    var b2b2c_order_amount = JSON.parse(localStorage.getItem("b2b2c_order_amount"));
    console.log("b2b2c_order_amount -------------- ");
    console.log(b2b2c_order_amount);

        if (config.server == "prod") {
          if (order_response && b2b2c_order_amount) {
            ReactGA.plugin.execute("ecommerce", "addTransaction", {
              id: order_response["orderNumber"],
              affiliation: "FP",
              revenue: b2b2c_order_amount,
              shipping: "0",
              tax: "0",
              currency: "USD"
            });
            ReactGA.plugin.execute("ecommerce", "send");
            ReactGA.plugin.execute("ecommerce", "clear");
            console.log("inserted  ");
          }
        }

    if (order_response) {
      window.scrollTo(0, 0);
      localStorage.removeItem("planType");
      localStorage.removeItem("planTerm");
      localStorage.removeItem("traveller");
      localStorage.removeItem("traveller-type");
      localStorage.removeItem("billing_information");
      localStorage.removeItem("order_response");
      localStorage.removeItem("promo");
    } else {
      await this.props.flushOrderData();
      this.props.history.push("/");
    }
  }

  _bindTravellerInfo(travellers) {
    return travellers.map((traveller, idx) => (
      <div key={idx}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            {traveller.first_name + " " + traveller.last_name}
          </Col>
          </Row>
        </div>
    ));
  }

  async componentWillUnmount() {
    await this.props.flushOrderData();
    this.props.history.push("/");
  }
  render() {
    let purchaseUrl = '';
    if(this.props.match.params.orgId){
      purchaseUrl = `${window.location.origin}/partner/purchase/${this.props.match.params.orgId}${window.location.search}`;
    } else {
      purchaseUrl = `${window.location.origin}/cap/purchase${window.location.search}`;
    }
    return (
      <section id="banner">
        <div className="thank-you thank-you-partner" id="thank-you">
          <div className="wrap">
            <div className="row">
              <div className="half-width">
                <div className="title-thanks" style={{marginTop: '30px'}}>
                  Thank You for Purchasing CAP™<br/>
                  <span style={{ fontSize: 17, float: 'left', width: '100%', padding: '0px 0 15px 2px', color: '#222', fontWeight: 'normal' }}>Provided to you in partnership with {this.props.resellerOrganizationInfo && this.props.resellerOrganizationInfo.b2B2COrgSettings && this.props.resellerOrganizationInfo.b2B2COrgSettings.orgName ? this.props.resellerOrganizationInfo.b2B2COrgSettings.orgName : 'Partner Inc.'}</span>
                </div>
                <div className="member" style={{paddingBottom: '0px', lineHeight: 'normal'}}>Your Plan Number is:</div>
                <div className="member-number">
                  {this.props.orderData && this.props.orderData.membership
                    ? this.props.orderData.membership
                    : "FPST-YYYYYYYYYY"}{" "}
                </div>
                <p
                  style={{
                    marginTop: "20px",
                    color: "#003da5",
                    fontSize: "18px",
                    marginBottom: "3px"
                  }}
                > Traveler(s): <br/></p>
              {this._bindTravellerInfo(this.props.personalDetails)}<br/>
                <p>
                <strong style={{color: '#2e6fad', fontStyle: 'italic'}}>Please keep a copy of your plan number and/or CAP™ Card with you during travel.</strong>
                </p>
              </div>
              <div className="half-width">
                <div style={{ position: "relative", float: "right" }}>
                  <img src={Card} alt="" style={{ maxWidth: "100%" }} />
                  <div className="number">
                    {this.props.orderData && this.props.orderData.membership
                      ? this.props.orderData.membership
                      : "FPST-YYYYYYYYYY"}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="full-width" style={{color: 'black', fontSize: '20px'}}>
              <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Need Help During Travel</strong>
                <p>For immediate assistance at any time during your trip, please call our Global Customer Response Center at <strong>+1-619-717-8549</strong> or text
                <strong> +1-954-727-1916</strong>. Please have your plan number and/or CAP™ Card readily available when you call, for verification purposes.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>How to Access the CAP™ Portal</strong>
                <p>You will receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>Welcome To CAP™</i> email with login credentials for the online CAP™ Travel Assistance Portal. A copy of your CAP™ Card
                can be found attached to this email. You will also receive a <i style={{color: '#2e6fad', fontStyle: 'italic'}}>purchase confirmation</i> email that includes a summary of your plan.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>What to Do if You Cannot Find the CAP™ Email in Your Inbox, Junk or Spam Folders</strong>
                <p>Please call <strong>+1-866-340-8569</strong> or email <a style={{color: '#2e6fad', textDecoration: 'underline'}} href="mailto: capsupport@wwfocus.com"><strong>capsupport@wwfocus.com</strong></a> for assistance. Please have your plan number and/or CAP™ Card
                readily available when you call.</p>
                <strong style={{marginBottom: '10px', display: 'block'}}>Share Your Experience</strong>
                <p>We’d love to hear from you. Feel free to leave us a review on Google, recommend us on Facebook, and follow us online.</p>
                <a href={purchaseUrl} style={{color: '#2e6fad', textDecoration: 'underline'}}>Continue Shopping</a><br></br>
                {/* <a  href="https://www.captravelassistance.com" style={{color: '#2e6fad', textDecoration: 'underline'}}>Go Back to the Home Page</a> */}
              </div>
              </div>
          </div>
        </div>
      </section>
      
    );
  }
}
ThankYou.propTypes = {
  orderData: PropTypes.object,
  SaveStep: PropTypes.func,
  flushOrderData: PropTypes.func,
  b2b2cBaseUrl:PropTypes.string

};
function mapStateToProps(state) {
  return {
    ...state.b2b2c
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ThankYou);