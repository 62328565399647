import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderB2C from "./header/header-b2c.jsx";
import B2cRouter from "../../routes/b2c-router";
import FooterB2c from "./footer/footer.jsx";
import * as b2cActions from "../../redux/actions/b2c-actions";
import ReactGA from 'react-ga';
import config from "../../../../config";
import ClarityScriptComponent from "../shared/components/clarityScript.jsx";

class B2c extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gaLoaded: false
    };
  }

    async componentDidMount() {
    await this.props.getProductPricingAndInfo();
    
    if (this.state.gaLoaded !== true && config.server == 'prod') {
     if (typeof ReactGA.initialize !== 'undefined') {
       ReactGA.initialize('G-N02B8BDNND');
     }
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.setState({ gaLoaded: true });
     }
  }

  render() {
    const { url } = this.props.match;

    return (
      <div>
        {/*<HeaderB2C />*/}
        <ClarityScriptComponent />

        <B2cRouter url={url} />
        {/*<FooterB2c />*/}
      </div>
    );
  }
}

B2c.propTypes = {
  getProductPricingAndInfo: PropTypes.func,
  location: PropTypes.object
};
function mapStateToProps(state) {
  return { ...state.b2c };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2cActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(B2c)
);
