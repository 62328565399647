import React, { useEffect } from 'react';

const ClarityScriptComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.clarity.ms/tag/offz2ddba7';

        
    // script.onload = () => {
    //   console.log('Clarity script loaded');
    // };

    script.onerror = () => {
      console.error('Error loading Clarity script');
    };

    
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return (
    <div>
    </div>
  );
};

export default ClarityScriptComponent;
