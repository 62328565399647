import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeaderB2B2C from "./header";
import B2B2CRouter from "../../routes/b2b2c-router";
import FooterB2B2C from "./footer";
import * as b2b2cActions from "../../redux/actions/b2b2c-actions";
import ReactGA from 'react-ga';
import Config from "../../../../config";
import { Spin } from "antd";
import BGImg from "../../images/main-bg.png";
import config from "../../../../config";
import ClarityScriptComponent from "../shared/components/clarityScript";

class B2B2C extends Component {
  constructor(props){
    super(props);
    this.state = {
        gaLoaded: false,
        dataLoaded: false
      };
  }
  
 

  async componentDidMount() {
    await this.props.getResellerProductPricingAndInfo();
    if (this.state.gaLoaded !== true && config.server == 'prod') {
      if (typeof ReactGA.initialize !== 'undefined') {
        ReactGA.initialize('G-N02B8BDNND');
      }
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.setState({ gaLoaded: true });
    }
  }
  
  render() {
    const { url } = this.props.match;
    let { resellerOrganizationInfo } = this.props;
    let backgroundImage = 'none'; 
    let primaryColor = null;
    if(this.props.resellerOrganizationInfo.b2B2COrgSettings && this.state.dataLoaded !== true){
      this.setState({ dataLoaded: true });
    }
    if(resellerOrganizationInfo.b2B2COrgSettings && Object.keys(resellerOrganizationInfo.b2B2COrgSettings).length > 0){
      resellerOrganizationInfo = resellerOrganizationInfo.b2B2COrgSettings;
      if(resellerOrganizationInfo.backgroundImage && resellerOrganizationInfo.backgroundImage !== null){
        backgroundImage = `url('${Config.API1 + "/" + encodeURIComponent(resellerOrganizationInfo.backgroundImage)}')`;
      } else {
        backgroundImage = `url(${BGImg})`;
      }
      if(resellerOrganizationInfo.primaryColor && resellerOrganizationInfo.primaryColor !== null){
        primaryColor =  resellerOrganizationInfo.primaryColor;
      }
    } else {
      if(this.state.dataLoaded !== false){
        backgroundImage = `url(${BGImg})`;
      }
    }
    return (
      <div>
        <style dangerouslySetInnerHTML={{__html: `
            #banner {
              background-image: ${backgroundImage} !important;
            }`
          }}
        />
        {primaryColor !== null && this.state.dataLoaded !== false ? 
          <style dangerouslySetInnerHTML={{__html: `
            #headerb2b2c .headerb2b2c-inner{
              border-color: ${primaryColor};
            }
            #banner .wizard ul li .st0{
              fill: ${primaryColor}; 
            }
            #banner .wizard ul li.active .st2, #banner .wizard ul li.active .st3, #banner .wizard ul li.active .st4, #banner .wizard ul li.active .st5{
              fill: ${primaryColor};
            }
            #banner .title h1{
              color: ${primaryColor};
            }
            #banner .three-box ul li h6{
              color: ${primaryColor};
            }
            #faq .accordion__button, #faq h3{
              color: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(1) .st0, #banner .plan-travel .in-div:nth-child(1) .st1{
              stroke: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(1) .st2{
              fill: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(2) .st0, #banner .plan-travel .in-div:nth-child(2) .st1{
              stroke: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(3) .st0{
              stroke: ${primaryColor};
            }
            #banner .personal-info h3{
              color: ${primaryColor};
            }
            #banner .add-traveler button{
              background: ${primaryColor};
            }
            #banner .traveller-alerts h3{
              color: ${primaryColor};
            }
            #banner .purchase-summary h3, #banner .billing-info h3{
              color: ${primaryColor};
            }
            #banner .billing-info .coupon-but {
              border-color: ${primaryColor};
              background: ${primaryColor};
            }
            #banner .billing-info .checkbox-in .container a{
              color: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(2) .st2{
              fill: ${primaryColor};
              stroke: ${primaryColor};
            }
            #banner .plan-travel .in-div:nth-child(3) .st1{
              stroke: ${primaryColor};
            }
            .product-upgrade-radio{
              color: ${primaryColor};
            }
          `}} />
        : '' }
        <HeaderB2B2C {...this.props}/>
        <ClarityScriptComponent />
        <Spin spinning={!this.state.dataLoaded}>
          <B2B2CRouter url={url} /> 
        </Spin>
        <FooterB2B2C {...this.props} />
      </div>
    );
  }
}

B2B2C.propTypes = {
  getResellerProductPricingAndInfo: PropTypes.func,
  location: PropTypes.object
};
function mapStateToProps(state) {
  return { ...state.b2b2c };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...b2b2cActions }, dispatch);
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(B2B2C)
);
